import React, { FC, memo, ReactNode } from 'react';

import classNames from 'classnames';

import * as styles from './Typography.module.scss';

type Variant =
  | 'Headline_1'
  | 'Headline_2'
  | 'Headline_3'
  | 'Headline_3_L'
  | 'Subtitle_1'
  | 'Subtitle_2'
  | 'Subtitle_3'
  | 'Subtitle_4'
  | 'Body_1'
  | 'Body_2'
  | 'Caption';

interface Props {
  variant: Variant;
  children?: ReactNode;
  className?: string;
  customTag?: string;
}

const Typography: FC<Props> = memo(({ className, variant, children, customTag }) => {
  const tagMap: { [key in Variant]?: string } = {
    Headline_1: 'h1',
    Headline_2: 'h2',
    Headline_3: 'h3',
    Subtitle_1: 'h4',
    Subtitle_2: 'h5',
    Subtitle_3: 'h6',
    Subtitle_4: 'h6',
    Body_1: 'p',
    Body_2: 'p',
    Caption: 'p',
  };
  // eslint-disable-next-line react/no-children-prop
  const tag = React.createElement(customTag || tagMap[variant] || 'span', {
    className: classNames(styles.typography, styles[variant], className),
    children,
  });
  return <>{tag}</>;
});

export default Typography;
