import React, { useState } from 'react';

import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { animated, useTransition } from 'react-spring';

import { ReactComponent as ArrowIcon } from 'images/arrow-icon.svg';
import PRNDLogo from 'images/prnd-logo.png';

import { appDownLink, desktopAppStoreLink, desktopGooglePlayLink, mailLink, prndLink } from '../../config';
import Typography from '../../design-system/Typography/Typography';

import * as styles from './Footer.module.scss';

const Footer: React.FC = () => {
  const [isOpenAppDownMenu, setOpenAppDownMenu] = useState(false);

  const onClickDesktopAppDown = () => {
    setOpenAppDownMenu((prev) => !prev);
  };

  const transitions = useTransition(isOpenAppDownMenu, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  return (
    <footer className={styles.footer}>
      <div className={styles.content}>
        <div className={styles.navWrapper}>
          <nav>
            <ul>
              <li>
                <Typography variant={'Subtitle_3'} className={classNames(styles.subTitle3, 'mobile')}>
                  서비스
                </Typography>
                <Typography variant={'Subtitle_2'} className={classNames(styles.subTitle3, 'desktop')}>
                  서비스
                </Typography>
              </li>
              <li className={styles.navItem}>
                <a href={appDownLink} className={'mobile'}>
                  <Typography variant={'Body_2'} className={styles.navItemText}>
                    앱 다운로드
                  </Typography>
                </a>
                <div className={classNames(styles.desktopAppDown, 'desktop')} onClick={onClickDesktopAppDown}>
                  <Typography variant={'Body_1'} className={classNames(styles.navItemText, styles.desktopAppDownText)}>
                    앱 다운로드{' '}
                    <ArrowIcon className={classNames(styles.arrowIcon, { [styles.isOpenArrow]: isOpenAppDownMenu })} />
                  </Typography>
                  {transitions(
                    (appDownMenu, open) =>
                      open && (
                        <animated.div style={appDownMenu} className={styles.appDownMenu}>
                          <a href={desktopGooglePlayLink} target="_blank" className={styles.googleLink}></a>
                          <div className={styles.border} />
                          <a href={desktopAppStoreLink} target="_blank" className={styles.appleLink}></a>
                        </animated.div>
                      )
                  )}
                </div>
              </li>
              <li className={styles.navItem}>
                <Link to="/qna">
                  <Typography variant={'Body_2'} className={classNames(styles.navItemText, 'mobile')}>
                    자주묻는 질문
                  </Typography>
                  <Typography variant={'Body_1'} className={classNames(styles.navItemText, 'desktop')}>
                    자주묻는 질문
                  </Typography>
                </Link>
              </li>
            </ul>
          </nav>
          <nav>
            <ul>
              <li>
                <Typography variant={'Subtitle_3'} className={classNames(styles.subTitle3, 'mobile')}>
                  회사
                </Typography>
                <Typography variant={'Subtitle_2'} className={classNames(styles.subTitle3, 'desktop')}>
                  회사
                </Typography>
              </li>
              <li className={styles.navItem}>
                <a href={prndLink} target="_blank">
                  <Typography variant={'Body_2'} className={classNames(styles.navItemText, 'mobile')}>
                    PRND 채용
                  </Typography>
                  <Typography variant={'Body_1'} className={classNames(styles.navItemText, 'desktop')}>
                    PRND 채용
                  </Typography>
                </a>
              </li>
              <li className={styles.navItem}>
                <a href={mailLink}>
                  <Typography variant={'Body_2'} className={classNames(styles.navItemText, 'mobile')}>
                    제휴문의
                  </Typography>
                  <Typography variant={'Body_1'} className={classNames(styles.navItemText, 'desktop')}>
                    제휴문의
                  </Typography>
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <div className={styles.serviceInfo}>
          <div className={classNames(styles.company, 'mobile')}>(주)피알앤디컴퍼니</div>
          <Typography variant="Subtitle_3" className={classNames(styles.desktopCompany, 'desktop')}>
            (주)피알앤디컴퍼니
          </Typography>

          <a href="https://api.heydealer.com/posts/ERJ1ZakN/" target="_blank" className={styles.privacyPolicy}>
            <Typography variant={'Caption'} className={classNames(styles.privacyPolicyText, 'mobile')}>
              서비스 이용약관 I 개인정보 처리방침
            </Typography>
            <Typography variant={'Body_2'} className={classNames(styles.privacyPolicyText, 'desktop')}>
              서비스 이용약관 I 개인정보 처리방침
            </Typography>
          </a>
          <div className={styles.prnd}>
            <Typography variant={'Caption'} className={classNames(styles.gray, 'mobile')}>
              2021 ⓒ PRND all rights reserved.
            </Typography>
            <Typography variant={'Body_2'} className={classNames(styles.gray, 'desktop')}>
              2021 ⓒ PRND all rights reserved.
            </Typography>
            <img src={PRNDLogo} alt="피알앤디 로고" className={styles.prndLogo} />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
