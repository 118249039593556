import React from 'react';

import classNames from 'classnames';

import Appstore from 'images/appstore.png';
import GooglePlay from 'images/google-play.png';

import { desktopAppStoreLink, desktopGooglePlayLink } from '../../../config';

import * as styles from './AppDownloadButtons.module.scss';

interface Props {
  size?: 'big' | 'small';
}

const AppDownloadButtons: React.FC<Props> = ({ size = 'big' }) => {
  return (
    <div>
      <a href={desktopGooglePlayLink} target="_blank" className={classNames(styles.download, styles[size])}>
        <img src={GooglePlay} alt="구글 플레이 앱 다운로드 버튼" className={styles.googlePlay} />
      </a>
      <a href={desktopAppStoreLink} target="_blank" className={classNames(styles.download, styles[size])}>
        <img src={Appstore} alt="앱 스토어 앱 다운로드 버튼" className={styles.appstore} />
      </a>
    </div>
  );
};

export default AppDownloadButtons;
