import React from 'react';

import { ReactComponent as Download } from 'images/download.svg';

import { appDownLink } from '../../../config';
import Button from '../../../design-system/Button/Button';

import * as styles from './AppDownloadButton.module.scss';
const AppDownloadButton: React.FC = () => {
  return (
    <Button href={appDownLink} text="앱 다운로드" className={styles.button} leftIcon={<Download className={styles.icon} />} />
  );
};

export default AppDownloadButton;
