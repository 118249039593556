import React, { useEffect } from 'react';

import DesktopHeader from '../DesktopHeader/DesktopHeader';
import Footer from '../Footer/Footer';
import MobileHeader from '../MobileHeader/MobileHeader';

import * as styles from './Layout.module.scss';

const Layout: React.FC = ({ children }) => {
  return (
    <>
      <div className={styles.headerWrapper}>
        <MobileHeader />
        <DesktopHeader />
      </div>
      {children}
      <Footer />
    </>
  );
};

export default Layout;
