import React from 'react';

import CopyWrite from 'images/copywrite.png';
import DesktopModel from 'images/desktop_model.png';

import TradedCarCount from '../TradedCarCount/TradedCarCount';

import GooglePlayAppDownloadButton from './AppDownloadButtons/AppDownloadButtons';

import * as styles from './DesktopSection1.module.scss';

const DesktopSection1: React.FC = () => {
  return (
    <div className={styles.desktopSection1}>
      <div className={styles.leftSection}>
        <div className={styles.leftContent}>
          <img src={CopyWrite} alt="우리가 바라던 내찰팔기" className={styles.copyWrite} />
          <div className={styles.tradedCarCountWrapper}>
            <TradedCarCount size="big" />
          </div>
          <div className={styles.appDownloadButtons}>
            <GooglePlayAppDownloadButton />
          </div>
        </div>
      </div>
      <div className={styles.rightSection}>
        <img src={DesktopModel} alt="모델이 헤이딜러 앱이 켜진 핸드폰을 들고 있는 사진" className={styles.model} />
      </div>
    </div>
  );
};

export default DesktopSection1;
