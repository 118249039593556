import React from 'react';

import classNames from 'classnames';

import Typography from '../../../design-system/Typography/Typography';

import * as styles from './SafeTradeCard.module.scss';

interface SafeTradeCard {
  title: string;
  description: string;
  image: string;
  fontColor: string;
  backgroundColor: string;
}
interface Props {
  safeTradeCard: SafeTradeCard;
  className?: string;
}
const SafeTradeCard: React.FC<Props> = ({
  safeTradeCard: { title, description, image, fontColor, backgroundColor },
  className,
}) => {
  return (
    <div className={classNames(styles.safeTradeCard, className)} style={{ color: fontColor, backgroundColor }}>
      <h3 className={styles.title}>{title}</h3>
      <img src={image} alt={title} className={styles.image} />
      <div className={styles.descriptionWrapper}>
        <Typography variant={'Body_2'} className="mobile">
          <span style={{ color: fontColor }}>{description}</span>
        </Typography>
        <Typography variant={'Body_1'} className="desktop">
          <span style={{ color: fontColor }}>{description}</span>
        </Typography>
      </div>
    </div>
  );
};

export default SafeTradeCard;
