import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { Link } from 'react-router-dom';

import DesktopLogo from 'images/desktop_logo.png';

import { mailLink, prndLink } from '../../config';
import Typography from '../../design-system/Typography/Typography';

import * as styles from './DesktopHeader.module.scss';
const DesktopHeader: React.FC = () => {
  const [scrollTop, setScrollTop] = useState(0);

  const onScroll = () => {
    setScrollTop(window.pageYOffset);
  };

  useEffect(() => {
    document.addEventListener('scroll', onScroll);
    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <div className={classNames(styles.desktopHeaderWrapper, { [styles.isNotTop]: scrollTop !== 0 })}>
      <header className={styles.header}>
        <Link to="/">
          <img src={DesktopLogo} alt="헤이딜러 로고" className={styles.logo} />
        </Link>
        <nav>
          <ul className={styles.section}>
            <li className={styles.listItem}>
              <Link to={'/qna'}>
                <div className={styles.buttonWrapper}>
                  <Typography variant="Body_1">자주묻는 질문</Typography>
                </div>
              </Link>
            </li>

            <li className={styles.listItem}>
              <a href={prndLink} className={styles.buttonWrapper} target={'_blank'}>
                <Typography variant="Body_1">PRND 채용</Typography>
              </a>
            </li>
            <li className={styles.listItem}>
              <a href={mailLink} className={styles.buttonWrapper} target={'_blank'}>
                <Typography variant="Body_1">제휴문의</Typography>
              </a>
            </li>
          </ul>
        </nav>
      </header>
    </div>
  );
};

export default DesktopHeader;
