import React, { useEffect, useRef, useState } from 'react';

import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import classNames from 'classnames';
import Div100vh from 'react-div-100vh';
import OutsideClickHandler from 'react-outside-click-handler';
import { Link } from 'react-router-dom';
import { useTransition, animated, useSpring } from 'react-spring';

import { ReactComponent as Menu } from 'images/menu.svg';
import MobileLogo from 'images/mobile_logo.png';

import SideMenu from './SideMenu/SideMenu';

import * as styles from './MobileHeader.module.scss';

const MobileHeader: React.FC = () => {
  const [scrollTop, setScrollTop] = useState(0);
  const [isOpenMenu, setOpenMenu] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const scrollEnableTargetRef = useRef<HTMLDivElement>(null);
  const onClickMenuIcon = () => {
    setOpenMenu((prev) => !prev);
  };

  const sideMenuSpringStyle = useSpring({ left: isOpenMenu ? '0%' : '100%' });

  const transitions = useTransition(isOpenMenu, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  useEffect(() => {
    if (scrollEnableTargetRef.current) {
      if (isOpenMenu) {
        setScrollPosition(window.pageYOffset);
        disableBodyScroll(scrollEnableTargetRef.current);
      } else {
        enableBodyScroll(scrollEnableTargetRef.current);
        window.scrollTo(0, scrollPosition);
      }
    }
    return () => {
      clearAllBodyScrollLocks();
    };
  }, [isOpenMenu]);

  const onScroll = () => {
    setScrollTop(window.pageYOffset);
  };

  useEffect(() => {
    document.addEventListener('scroll', onScroll);
    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, []);
  return (
    <>
      <header className={classNames(styles.header, { [styles.isNotTop]: scrollTop !== 0 })}>
        <Link to={'/'}>
          <img src={MobileLogo} alt="헤이딜러 로고" className={styles.logo} />
        </Link>
        <Menu className={styles.icon} onClick={onClickMenuIcon} />
      </header>
      {transitions((dimmerSpringStyle, open) => open && <animated.div style={dimmerSpringStyle} className={styles.dimmer} />)}
      <animated.div style={sideMenuSpringStyle} className={styles.sideMenu}>
        <Div100vh className={styles.container}>
          <OutsideClickHandler onOutsideClick={() => setOpenMenu(false)}>
            <div className={styles.content} ref={scrollEnableTargetRef}>
              <SideMenu onClose={() => setOpenMenu(false)} />
            </div>
          </OutsideClickHandler>
        </Div100vh>
      </animated.div>
    </>
  );
};

export default MobileHeader;
