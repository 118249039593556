import React from 'react';

import classNames from 'classnames';
import { useInView } from 'react-intersection-observer';

import * as styles from './Section2.module.scss';

const Section2: React.FC = () => {
  const [section2Ref, section2InView] = useInView({
    threshold: 0.4,
    triggerOnce: true,
  });
  return (
    <section className={classNames(styles.section)} ref={section2Ref}>
      <h2 className={classNames(styles.mobileSubTitle, { [styles.view]: section2InView })}>
        <span className={styles.gray}>
          제값 받기 어렵고
          <br /> 경험도 불편하던 중고차 거래,
        </span>
        <br /> 헤이딜러가 바꿔나갑니다.
      </h2>

      <h2 className={classNames(styles.desktopSubTitle, { [styles.view]: section2InView })}>
        <span className={styles.gray}>제값 받기 어렵고 경험도 불편하던 중고차 거래,</span>
        <br /> 헤이딜러가 바꿔나갑니다.
      </h2>
    </section>
  );
};

export default Section2;
