import React from 'react';

import classNames from 'classnames';
import { useInView } from 'react-intersection-observer';

import DealerProfile1 from 'images/dealer-profile1.png';
import DealerProfile2 from 'images/dealer-profile2.png';

import Typography from '../../design-system/Typography/Typography';

import DealerProfileCarousel from './DealerProfileCarousel/DealerProfileCarousel';

import * as styles from './Section5.module.scss';

const Section5: React.FC = () => {
  const [sectionRef, sectionInView] = useInView({
    threshold: 0.4,
    triggerOnce: true,
  });
  return (
    <section className={styles.section} ref={sectionRef}>
      <>
        <div className={classNames(styles.mobileContent, { [styles.view]: sectionInView }, 'mobile')}>
          <h2 className={styles.mobileTitle}>
            검증된 딜러와 <br />
            집앞거래
          </h2>
          <Typography variant="Body_1" className={styles.subTitle}>
            헤이딜러는 감가내역까지 투명하게 공개
          </Typography>
        </div>
        <div className={classNames(styles.carousel, { [styles.view]: sectionInView }, 'mobile')}>
          <DealerProfileCarousel />
        </div>
      </>

      <>
        <div className={classNames(styles.leftSection, 'desktop')}>
          <div
            className={classNames(styles.content, {
              [styles.view]: sectionInView,
            })}
          >
            <h2 className={styles.desktopTitle}>
              검증된 딜러와 <br />
              집앞거래
            </h2>
            <h4 className={styles.desktopSubTitle}>헤이딜러는 감가내역까지 투명하게 공개</h4>
          </div>
        </div>
        <div className={classNames(styles.rightSection, 'desktop')}>
          <img
            src={DealerProfile1}
            alt="딜러 프로필 사진"
            className={classNames(styles.dealerProfileImage1, {
              [styles.view]: sectionInView,
            })}
          />
          <img
            src={DealerProfile2}
            alt="딜러 리뷰 사진"
            className={classNames(styles.dealerProfileImage2, {
              [styles.view]: sectionInView,
            })}
          />
        </div>
      </>
    </section>
  );
};

export default Section5;
