import React from 'react';

import { Link } from 'react-router-dom';

import { ReactComponent as Close } from 'images/close.svg';

import { appDownLink, mailLink, prndLink } from '../../../config';
import Typography from '../../../design-system/Typography/Typography';
import Ripple from '../../Ripple/Ripple';

import * as styles from './SideMenu.module.scss';

interface Props {
  onClose: () => void;
}
const SideMenu: React.FC<Props> = ({ onClose }) => {
  return (
    <div>
      <header className={styles.header}>
        <Close className={styles.icon} onClick={onClose} />
      </header>
      <nav>
        <ul className={styles.section}>
          <li>
            <Typography variant="Subtitle_2" className={styles.subTitle}>
              서비스
            </Typography>
          </li>
          <li>
            <a href={appDownLink} className={styles.listItem} target={'_blank'}>
              <Typography variant="Body_1">앱다운로드</Typography>
              <Ripple />
            </a>
          </li>
          <li>
            <Link to="/qna">
              <div className={styles.listItem}>
                <Typography variant="Body_1">자주묻는 질문</Typography>
              </div>
            </Link>
          </li>
        </ul>
        <ul className={styles.section}>
          <li>
            <Typography variant="Subtitle_2" className={styles.subTitle}>
              회사
            </Typography>
          </li>
          <li>
            <a href={prndLink} className={styles.listItem} target={'_blank'}>
              <Typography variant="Body_1">PRND 채용</Typography>
              <Ripple />
            </a>
          </li>
          <li>
            <a href={mailLink} className={styles.listItem} target={'_blank'}>
              <Typography variant="Body_1">제휴문의</Typography>
              <Ripple />
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default SideMenu;
