import React from 'react';

import classNames from 'classnames';
import { useInView } from 'react-intersection-observer';

import DesktopSection4 from 'images/desktop-section4.png';
import MobileSection4 from 'images/mobile-section4.png';

import Typography from '../../design-system/Typography/Typography';

import * as styles from './Section4.module.scss';

const Section4: React.FC = () => {
  const [sectionRef, sectionInView] = useInView({
    threshold: 0.4,
    triggerOnce: true,
  });

  return (
    <section className={styles.section} ref={sectionRef}>
      <div className={classNames(styles.mobileContent, { [styles.view]: sectionInView }, 'mobile')}>
        <h2 className={styles.mobileTitle}>
          좋은 값에 <br />
          팔고 싶다면,
        </h2>
        <Typography variant="Body_1" className={styles.subTitle}>
          헤이딜러 경매로 7,000명 딜러경쟁
        </Typography>
        <Typography variant="Caption" className={styles.caption}>
          *연락처는 판매요청 전까지 딜러에게 공개되지 않아요.
        </Typography>
        <img
          src={MobileSection4}
          alt="헤이딜러 시세조회 화면"
          className={classNames(styles.section4MobileImage, { [styles.imageView]: sectionInView })}
        />
      </div>
      <>
        <div className={classNames(styles.leftSection, 'desktop')}>
          <img
            src={DesktopSection4}
            alt="헤이딜러 시세조회 화면"
            className={classNames(styles.desktopSection4Image, { [styles.view]: sectionInView })}
          />
        </div>
        <div className={classNames(styles.rightSection, 'desktop')}>
          <div className={classNames(styles.content, { [styles.view]: sectionInView })}>
            <h2 className={styles.desktopTitle}>
              좋은 값에 <br />
              팔고 싶다면,
            </h2>
            <h4 className={styles.desktopSubTitle}>헤이딜러 경매로 7,000명 딜러경쟁</h4>
            <Typography variant={'Body_2'} className={styles.desktopCaption}>
              *연락처는 판매요청 전까지 딜러에게 공개되지 않아요.
            </Typography>
          </div>
        </div>
      </>
    </section>
  );
};

export default Section4;
