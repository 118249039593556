import React from 'react';

import classNames from 'classnames';
import { useInView } from 'react-intersection-observer';

import SafeTrade1 from 'images/safe-trade1.png';
import SafeTrade2 from 'images/safe-trade2.png';
import SafeTrade3 from 'images/safe-trade3.png';

import SafeTradeCard from './SafeTradeCard/SafeTradeCard';
import SafeTradeCarousel from './SafeTradeCarousel/SafeTradeCarousel';

import * as styles from './Section6.module.scss';

export const safeTradeCards = [
  {
    title: '부당감가 보상제',
    description: '감가심사센터 검토 후\n' + '부당한 감가를 돌려드립니다.',
    image: SafeTrade1,
    fontColor: '#F7F8FB',
    backgroundColor: '#272E40',
  },
  {
    title: '48시간 내 명의이전',
    description: '이전에 문제가 생기면,\n 헤이딜러가 직접 처리합니다.',
    image: SafeTrade2,
    fontColor: '#F7F8FB',
    backgroundColor: '#869AB7',
  },
  {
    title: '365일 채팅상담',
    description: '휴일에도 편하게 도움을\n 요청하세요.',
    image: SafeTrade3,
    fontColor: '#414D6B',
    backgroundColor: '#E9EDF4',
  },
];
const Section6: React.FC = () => {
  const [sectionRef, sectionInView] = useInView({
    threshold: 0.4,
    triggerOnce: true,
  });

  return (
    <section className={styles.section} ref={sectionRef}>
      <>
        <h2 className={classNames(styles.mobileTitle, { [styles.view]: sectionInView }, 'mobile')}>
          안심거래 책임지는
          <br /> 유일한 플랫폼
        </h2>
        <div className={classNames(styles.safeTradeCarouselWrapperMobile, { [styles.view]: sectionInView }, 'mobile')}>
          <SafeTradeCarousel />
        </div>
      </>
      <div className={'desktop'}>
        <h2 className={classNames(styles.desktopTitle, { [styles.view]: sectionInView })}>
          안심거래 책임지는
          <br /> 유일한 플랫폼
        </h2>
        <div className={styles.safeTradeCarouselWrapperDesktop}>
          <SafeTradeCard
            safeTradeCard={safeTradeCards[0]}
            className={classNames(styles.safeTradeCard1, { [styles.view]: sectionInView })}
          />
          <SafeTradeCard
            safeTradeCard={safeTradeCards[1]}
            className={classNames(styles.safeTradeCard2, { [styles.view]: sectionInView })}
          />
          <SafeTradeCard
            safeTradeCard={safeTradeCards[2]}
            className={classNames(styles.safeTradeCard3, { [styles.view]: sectionInView })}
          />
        </div>
      </div>
    </section>
  );
};

export default Section6;
