import React, { FC, useState } from 'react';

import { useBreakPoint } from '../../hooks/useBreakPoint';
import QnAItem from '../QnAItem/QnAItem';

import * as styles from './QnA.module.scss';

const QnA: FC = () => {
  const [openCollapse, setOpenCollapse] = useState<number | null>(null);
  const isMobile = useBreakPoint();
  const onClickDesktopReductionCenter = () => {
    window.open('https://page.heydealer.com/reduction', '_blank', `width=600, height=900, scrollbars=yes`);
  };

  const onClickDesktopReductionStandard = () => {
    window.open('http://api.heydealer.com/posts/p4J3n5wr', '_blank', `width=600, height=900, scrollbars=yes`);
  };
  const faqs = [
    {
      title: '헤이딜러 이용료는 없나요?',
      content: '네 그렇습니다.\n 내차 시세조회부터 딜러들에게 견적을 받아 판매하는 과정까지 무료입니다.',
    },
    {
      title: '예상 시세는 어떻게 계산되나요?',
      content: (
        <div>
          비슷한 차의 헤이딜러 경매 데이터로 계산됩니다.{!isMobile && <br />} 사고ㆍ상태ㆍ옵션은 고려되지 않은 평균 시세이기
          때문에 더 정확한 내 차의 견적이 궁금하시면,{!isMobile && <br />} 실제 딜러 견적을 받아보세요.
        </div>
      ),
    },
    {
      title: '견적만 받아봐도 되나요?',
      content: '네 물론입니다. \n' + '판매 의무가 없으므로 편하게 받아보세요.',
    },
    {
      title: '견적 받은 후 딜러에게 연락이 오나요?',
      content: '아니요. 그렇지 않습니다.\n' + '고객님이 판매 요청하기 전까지 딜러에게 고객님의 연락처가 공개되지 않습니다.',
    },
    {
      title: '거래 과정에서 감가가 되나요?',
      content: (
        <div>
          차량 상태에 대해서 정확하게 알려주셨다면 감가 되지 않아요. <br />
          <div className={styles.paragraph}>
            미리 알려주시지 않은 감가 사유가 있다면 헤이딜러
            {isMobile ? (
              <a href="https://page.heydealer.com/reduction" className={styles.link} target="_blank">
                &nbsp;감가심사센터
              </a>
            ) : (
              <span className={styles.link} onClick={onClickDesktopReductionCenter}>
                &nbsp;감가심사센터
              </span>
            )}
            에서
            {isMobile ? (
              <a href="http://api.heydealer.com/posts/p4J3n5wr/" className={styles.link} target="_blank">
                &nbsp;헤이딜러 감가기준&nbsp;
              </a>
            ) : (
              <span className={styles.link} onClick={onClickDesktopReductionStandard}>
                &nbsp;헤이딜러 감가기준&nbsp;
              </span>
            )}
            내에서 조율해 드립니다.
            <br />
          </div>
          <div className={styles.paragraph}>혹여 딜러가 부당하게 감가했을 경우 헤이딜러에서 책임지고 돌려드립니다.</div>
        </div>
      ),
    },
  ];
  return (
    <>
      <div className={styles.faq}>
        {faqs.map((faq, index) => {
          return (
            <QnAItem
              key={index}
              title={faq.title}
              content={faq.content}
              collapseNumber={index}
              openCollapse={openCollapse}
              setOpenCollapse={setOpenCollapse}
            />
          );
        })}
      </div>
    </>
  );
};

export default QnA;
