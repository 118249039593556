import * as React from 'react';

import 'styles/index.scss';
import DesktopSection1 from '../../components/DesktopSection1/DesktopSection1';
import Layout from '../../components/Layout/Layout';
import MobileSection1 from '../../components/MobileSection1/MobileSection1';
import Section2 from '../../components/Section2/Section2';
import Section3 from '../../components/Section3/Section3';
import Section4 from '../../components/Section4/Section4';
import Section5 from '../../components/Section5/Section5';
import Section6 from '../../components/Section6/Section6';

import 'swiper/swiper.scss';
import styles from './index.module.scss';

const IndexPage = () => {
  return (
    <Layout>
      <div className={styles.section1}>
        <MobileSection1 />
        <DesktopSection1 />
      </div>
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
    </Layout>
  );
};

export default IndexPage;
