import React from 'react';

import CopyWrite from 'images/copywrite.png';
import Model from 'images/model_mobile.png';

import TradedCarCount from '../TradedCarCount/TradedCarCount';

import AppDownloadButton from './AppDownloadButton/AppDownloadButton';

import * as styles from './MobileSection1.module.scss';

const MobileSection1: React.FC = () => {
  return (
    <div className={styles.section1}>
      <div className={styles.content}>
        <span className={styles.copyWriteWrapper}>
          <img src={CopyWrite} alt="우리가 바라던 내찰팔기" className={styles.copyWrite} />
        </span>
      </div>
      <div className={styles.modelWrapper}>
        <img src={Model} alt="모델이 헤이딜러 앱이 켜진 핸드폰을 들고 있는 사진" className={styles.model} />
        <div className={styles.tradeCount}>
          <TradedCarCount />
        </div>
      </div>
      <div className={styles.appDownloadButtonWrapper}>
        <AppDownloadButton />
      </div>
    </div>
  );
};

export default MobileSection1;
