import React from 'react';

import { animated, useSpring } from 'react-spring';

import Typography from '../../design-system/Typography/Typography';

import * as styles from './TradedCarCount.module.scss';

interface Props {
  size?: 'big' | 'small';
}

const TradedCarCount: React.FC<Props> = ({ size = 'small' }) => {
  const countStyleProps = useSpring({ to: { count: 323421 }, from: { count: 0 } });
  return (
    <Typography variant={size === 'big' ? 'Subtitle_1' : 'Subtitle_2'}>
      누적거래&nbsp;
      <animated.span className={styles.count}>
        {countStyleProps.count.to((number) => Math.round(number).toLocaleString())}
      </animated.span>
      대
    </Typography>
  );
};

export default TradedCarCount;
